export default class PostTableItem {
    postId = 0;
    postStatus = 0;
    postSortId = 0;
    postName = "";
    postRemark = "";
    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";
}
